import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Navbar, Nav } from "react-bootstrap"

import "../Layout.css"

const Header = () => {
  const contentfulData = useStaticQuery(graphql`
  query {
    allContentfulNavigationBar {
      nodes {
        logoImage {
          file {
            url
          }
        }
        logoImageAltText
        menuItem1Name
        menuItem1Link
        menuItem2Name
        menuItem2Link
        menuItem3Name
        menuItem3Link
        menuItem4Name
        menuItem4Link
      }
    }
  }
  `)

  return (
  <header
    style={{ 
      background: `black`}}>
    <Navbar 
      expand="lg"
      collapseOnSelect
      className="navbar-dark"
      style={{backgroundColor: "black", maxHeight: "65px", height:"65px", paddingLeft:"20px!important", paddingRight:"20px!important"}}
      fixed="top"
      >
      <Navbar.Brand
        href="/">
        <img 
          src={contentfulData.allContentfulNavigationBar.nodes[0].logoImage.file.url}
          width="200"
          alt={contentfulData.allContentfulNavigationBar.nodes[0].logoImageAltText}
          style={{marginBottom: "0px"}}/>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto" pullRight style={{backgroundColor: "black"}}>, 
          {/* <Nav.Link 
            className="navbar-home"
            href={ contentfulData.allContentfulNavigationBar.nodes[0].menuItem1Link } 
          >
            { contentfulData.allContentfulNavigationBar.nodes[0].menuItem1Name }
          </Nav.Link>
          <Nav.Link 
            className="navbar-home"
            href={ contentfulData.allContentfulNavigationBar.nodes[0].menuItem2Link } 
          >
            { contentfulData.allContentfulNavigationBar.nodes[0].menuItem2Name }
          </Nav.Link>
          <Nav.Link 
            className="navbar-home"
            href={ contentfulData.allContentfulNavigationBar.nodes[0].menuItem3Link } 
          >
            { contentfulData.allContentfulNavigationBar.nodes[0].menuItem3Name }
          </Nav.Link> */}
          <Nav.Link 
            className="navbar-home"
            href="/contact"
          >
            { contentfulData.allContentfulNavigationBar.nodes[0].menuItem4Name }
          </Nav.Link>
        </Nav>
        </Navbar.Collapse>
    </Navbar>
  </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteNavLocations: PropTypes.array
}

Header.defaultProps = {
  siteTitle: ``,
  siteLogo: '../images/logoWhite.svg',
}

export default Header
