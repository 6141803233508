import React from "react"
import { Col, Row, Container } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { instagramIcon } from '../../images/instagramIcon.png'
import "../Layout.css"

const Footer = () => {
    return (
<footer className="page-footer font-small pt-4" style={{backgroundColor:"black", maxHeight:"300px"}}>
  <Container fluid className="text-center text-md-left" style={{color:"white"}}>
    <Row>
    <Col md={1} lg={1} xl={1} id="footerList"></Col>
      <Col xs={6} sm={6} md={5} lg={5} xl={5}>
        <p className="footer-title">Tyffonium</p>
        <ul className="custom-list">
          <li>
            <a style={{color:"white",fontSize: ".75rem"}} href="#!">Attractions</a>
          </li>
          <li>
            <a style={{color:"white",fontSize: ".75rem"}} href="#!">Cafe</a>
          </li>
          <li>
            <a style={{color:"white",fontSize: ".75rem"}} href="#!">About</a>
          </li>
          <li>
            <a style={{color:"white",fontSize: ".75rem"}} href="/Contact">FAQ</a>
          </li>
          <li>
            <a style={{color:"white",fontSize: ".75rem"}} href="/Contact">Contact</a>
          </li>
          <li>
            <a style={{color:"white",fontSize: ".75rem"}} href="#!">Careers</a>
          </li>
          <li>
            <a style={{color:"white",fontSize: ".75rem"}} href="#!">Tickets</a>
          </li>
        </ul>
      </Col>
      <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
      <Col xs={4} sm={4} md={5} lg={5} xl={5}>
        <Row>
            <p className="footer-title">Connect</p>
        </Row>
        <Row>
            <i style={{margin:"5px"}}><span className='instagram-icon'></span></i>
            <FontAwesomeIcon style={{margin:"5px"}} icon={faFacebook} size="3x" color="#3b5998"></FontAwesomeIcon>
            <FontAwesomeIcon style={{margin:"5px"}} icon={faTwitter} size="3x" color="#1da1f2"></FontAwesomeIcon>
        </Row>
        <Row>
            <a style={{marginTop: "5px"}}>@TYFFONIUMSM</a>
        </Row>
      </Col>       
    </Row>
  </Container>
  <div className="footer-copyright text-center pt-3 footer-terms-row">
      <p style={{color:"white",fontSize: ".75rem"}}><a >Terms &amp; Conditions</a> / <a>Private Policy</a></p>
      <p style={{color:"white",fontSize: ".75rem", marginBottom: "unset"}}>Copyright © 2011-2019 Tyffon Inc. All rights reserved</p>
  </div>
</footer>
    )
}

export default Footer